<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-form ref="supportForm" lazy-validation>
                    <v-card borderless>
                        <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                            Support
                        </v-card-title>
                        <v-card borderless>

                            <v-card-text class="text-body-1 text--primary mt-4">
                                <v-row v-if="!currentLE">
                                    <v-form>
                                        <v-row>

                                            <v-col cols="auto">
                                                <v-text-field label="Leistungserbringer" v-model="leistungserbringer"
                                                              maxlength="9" inputmode="numeric" pattern="[0-9]"
                                                              class="d-inline-flex" autocomplete="off"
                                                              required clearable validate-on-blur
                                                              @click:clear="resetData" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn color="primary" type="submit"
                                                       @click.stop.prevent="getLEInfo()">

                                                    Suchen
                                                </v-btn>
                                            </v-col>
                                            <!--<v-btn color="primary" type="submit" class="d-block mt-4"
                                                   :disabled="!leistungserbringer || leistungserbringer.length < 9 || loading"
                                                   :loading="loading" @click.stop.prevent="$_fetchLEData">

                                                {{ buttonLabel }}
                                            </v-btn>-->
                                        </v-row>
                                        <v-alert border="left" type="error" v-if="errorMessage" class="mt-4">
                                            {{ errorMessage }}
                                        </v-alert>
                                    </v-form>
                                </v-row>

                                <v-row>
                                    <v-col cols="3">
                                        <!--<p class="text-h6 text--secondary  mt-2">-->
                                        <strong>IK:</strong>  {{leDaten.ik}}
                                        <!--</p>-->
                                    </v-col>
                                    <v-col cols="4">
                                        <strong>Name:</strong> {{leDaten.name}}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <strong>Ort:</strong>  {{leDaten.ort}}
                                    </v-col>
                                    <v-col cols="4">
                                        <strong>Straße:</strong> {{leDaten.strasse}}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <strong>Status:</strong>
                                        <span v-if="leDaten.teilnahmeStatus"> aktiviert (Seit {{moment(String(leDaten.gvDatum)).format('DD.MM.YYYY HH:mm')}})</span>
                                        <span v-else> nicht aktiviert</span>
                                    </v-col>
                                    <v-col>
                                        <strong>E-Mail:</strong>  {{leDaten.benutzerEmail}}
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-expansion-panels v-model="panel"
                                                        multiple>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="text-h6">Registrierung</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col cols="3">
                                                        <!--<p class="text-h6 text--secondary  mt-2">-->
                                                        TeilnahmeCode
                                                        <!--</p>-->
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.guid}}
                                                    </v-col>
                                                   
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="2">
                                                        <v-select label="Anrede" v-model="benutzer.anrede"
                                                                  :items="$store.state.anrede" item-text="bezeichnung" item-value="anrede"
                                                                  :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                                                  :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" dense clearable
                                                                  :disabled="!regCodeEnabled"
                                                                  :rules="eingabeRules.anrede" />
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field label="Vorname" v-model="benutzer.vorname"
                                                                      autocomplete="off"
                                                                      dense clearable :disabled="!regCodeEnabled"
                                                                      :rules="eingabeRules.name" />
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-text-field label="Nachname" v-model="benutzer.nachname"
                                                                      autocomplete="off"
                                                                      dense clearable :disabled="!regCodeEnabled"
                                                                      :rules="eingabeRules.name" />
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn color="primary" type="submit"
                                                               @click.stop.prevent="sendRegCode(leDaten.ik, true)"
                                                               :disabled="!regCodeEnabled">
                                                            erneut versenden
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="auto" v-if="showMsg === 1">
                                                        <span>
                                                            {{supportMsg}}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        TeilnahmeCode erstellt am
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.gDatumVon != null ? moment(String(leDaten.gDatumVon)).format('DD.MM.YYYY HH:mm') : null}}
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        TeilnahmeCode gültig bis
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.gDatumBis!= null ? moment(String(leDaten.gDatumBis)).format('DD.MM.YYYY HH:mm') : null}}
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn color="primary" type="submit" class="px-10"
                                                               @click.stop.prevent="sendRegCode(leDaten.ik, false)"
                                                               :disabled="!regCodeEnabled">
                                                            verlängern
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="auto" v-if="showMsg === 2">
                                                        <span>
                                                            {{supportMsg}}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        Status des TeilnahmeCodes
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.benutzerkontoStatusBezeichnung}}
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        Aktiviert für Buchungsmonate ab
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.bMonatVon}}
                                                        <span v-if="leDaten.bMonatBis < 9912"> bis  {{leDaten.bMonatBis}}</span>
                                                    </v-col>

                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="text-h6">Verifizierung</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col cols="3">
                                                        <!--<p class="text-h6 text--secondary  mt-2">-->
                                                        Verifizierungslink per E-Mail
                                                        versendet am
                                                        <!--</p>-->
                                                    </v-col>
                                                    <v-col cols="3">
                                                        {{leDaten.verifizierungDatum != null ? moment(String(leDaten.verifizierungDatum)).format('DD.MM.YYYY HH:mm') : null}}
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        Verifizierungslink versendet an
                                                    </v-col>

                                                    <v-col cols="4" class="flex-grow-1">
                                                        <v-text-field label="Email" v-model="emailNew" :rules="emailRules"
                                                                      autocomplete="email" dense hide-details="auto"
                                                                      :readonly="!verifizierungEnabled" />


                                                    </v-col>
                                                    <v-col cols="auto" class="pl-0">
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" @click="changeEmail()" icon
                                                                       :disabled="emailDisabled || !verifizierungEnabled">

                                                                    <v-icon color="primary">$vuetify.icons.contentSave</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Email-Adresse speichern</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col cols="auto" class="pl-0" v-if="leDaten.benutzerEmail !== emailNew  && verifizierungEnabled">
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" @click="cancel()" icon>

                                                                    <v-icon color="primary">$vuetify.icons.cancel</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Abbrechen</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn color="primary" type="submit"
                                                               @click.stop.prevent="sendVerifizierungsmail(leDaten.ik)"
                                                               :disabled=" !verifizierungEnabled || !emailDisabled">
                                                            erneut versenden
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="auto" v-if="showMsg === 3">
                                                        <span>
                                                            {{supportMsg}}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="text-h6">Zwei-Faktor-Authentifizierung</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col cols="3">
                                                        <!--<p class="text-h6 text--secondary  mt-2">-->
                                                        2FA-Link per E-Mail
                                                        versendet am
                                                        <!--</p>-->
                                                    </v-col>
                                                    <v-col cols="3">
                                                        {{leDaten.secret2FADatum != null ? moment(String(leDaten.secret2FADatum)).format('DD.MM.YYYY HH:mm') : null}}
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        <!--<p class="text-h6 text--secondary  mt-2">-->
                                                        QR-Code (Schlüssel)
                                                        <!--</p>-->
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.secret2FA}}
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="3">
                                                        QR-Code versendet an
                                                    </v-col>
                                                    <v-col cols="4">
                                                        {{leDaten.benutzerEmail}}
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-btn color="primary" type="submit"
                                                               @click.stop.prevent="sendZweiFA(leDaten.loginname)"
                                                               :disabled="!zweiFaktorAuthEnabled ">
                                                            erneut versenden
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="auto" v-if="showMsg === 4">
                                                        <span>
                                                            {{supportMsg}}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-row>
                                <!--<v-select v-model="editedItem.anrede"
                                          :items="$store.state.defCache.anrede" item-text="Bezeichnung" item-value="Anrede"
                                          :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                          :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" clearable
                                          v-if="item.loginname === editedItem.loginname" dense />
                                <span v-else>{{item.anrede
                                                            }}</span>-->
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import TableEinspruchPositionen from '@/components/TableEinspruchPositionen.vue';
    import SwitchIk from '@/components/SwitchIK.vue';
    import eventBus from '@/main';
    import axios from 'axios';
    import moment from 'moment';
    export default {
        components: {
            TableEinspruchPositionen,
            SwitchIk,
        },
        data() {
            return {
                navLogout: { title: 'Abmelden', icon: '$vuetify.icons.logout', method: this.logout },
                timeToLogout: null,
                sessionExpired: false,
                leistungserbringer: null,
                leDaten: {
                    //ik: 307706689, name: "Central-Apotheke Robert Herold", ort: "Dresden", strasse: "Bahnhofstrasse 5", status: "aktiviert", statusDt: "13.05.2023",
                    //email: "info@apotheke-central.de",
                    //teilnahmeCode: "2ACAE82F-F2E7-4CCD-89C0-DA283BEE9BDF", tcEstellungsDt: "02.11.2023", tcAblaufDt: "28.12.2023", tcStatus: "aktiv", tcAtivBM: "2310 - 9912",
                    //VerifzierungVersandDt: "21.11.2023",
                    //qrCodeKey: "R6HG VY62 VNZX 2C7V ZSA4 WLH6 V2PD PGBB"
                },
                emailRules: [
                    v => !!v || 'Email-Adresse ist erforderlich',
                    v => /^.+@.+\..+$/.test(v) || 'Geben Sie eine gültige E-Mail-Adresse ein'
                ],
                emailNew: '',
                errorMessage: '',
                loading: false,
                showMsg: 0,   // 0 kein Message, 1: RegCode verlängern, 2: RegCode neu versenden, 3: Verifizierungscode, 4: 2FA-Code
                supportMsg: '',
                panel: [],
                benutzer: {
                    anrede: '',
                    nachname: '',
                    vorname: '',
                },
                anrede: [
                        { Anrede: 1, Bezeichnung: 'Frau' },
                        { Anrede: 2, Bezeichnung: 'Herr' },
                        { Anrede: 3, Bezeichnung: 'Divers' },
                ],
                eingabeRules: {
                    name: [
                        v => !!v || 'Eingabe ist erforderlich',
                        v => v && !!v.trim() || 'Eingabe darf kein Leezeichen sein',

                    ],
                    anrede: [
                        v => !!v || 'Eingabe ist erforderlich'

                    ]
                },
            }
        },
        computed: {
            emailDisabled() {
                return this.leDaten.benutzerEmail === this.emailNew;
            },
            currentLE() {
                return this.$store.state.currentUser?.leistungserbringer;
            },
            isUserLoggedIn() {
                return this.$store.state.currentUser
                    && this.$store.state.currentUser.leistungserbringer
                    && !this.sessionExpired;
            },
            timeToLogoutText() {
                if (!this.timeToLogout)
                    return null;
                return String(this.timeToLogout.minutes()).padStart(2, '0') + ':' +
                    String(this.timeToLogout.seconds()).padStart(2, '0');
            },
            //disabled() {
            //    return this.leDaten.benutzerkontoStatus > 1;
            //},
            regCodeEnabled() {
                return this.leDaten.teilnahmeStatus < 1;
            },
            regCodeSendEnabled() {
                return this.leDaten.teilnahmeStatus < 1 && this.benutzer.anrede && this.benutzer.nachname
            },
            verifizierungEnabled() {
                return this.leDaten.benutzerkontoStatus === 1;
            },
            zweiFaktorAuthEnabled() {
                return this.leDaten.benutzerkontoStatus > 1 && this.leDaten.benutzerkontoStatus < 3;
            }
            
        },
        watch: {
            currentLE(newValue) {
                if (newValue)
                    this.resetData();
            },
        },
        //created() {
        //    eventBus.$on("EinspruchListeReload", this.reloadEinspruchListe);
        //},
        activated() {
            this.resetData();
            this.$nextTick(() => {
                if (this.currentLE) {
                    this.leistungserbringer = this.currentLE;
                    // token erneuern und timer zurücksetzen, bei Fehler ausloggen
                    this.getLEInfo();
                }
            });
        },
        mounted() {
            this.resetData();
        },
        methods: {
            initialize() {
                this.leistungserbringer = null;
                this.leDaten = {};
                this.emailNew = '';
                this.errorMessage = '';
                this.showMsg = 0;
                this.supportMsg = "";
                this.loading = false;
                this.panel = [];
                this.$refs.supportForm.resetValidation();

            },
            resetData() {
                this.initialize();
            },
            async changeEmail() {
                this.waitingForCaptcha = true;
                this.errorMessage = "";
                this.showMsg = 0;
                this.SupportMsg = "";
                // register verification request in backend
                this.$http.post('support/changeemail', {
                    IK: this.leistungserbringer,
                    EMailAlt: this.leDaten.benutzerEmail,
                    EMailNeu: this.emailNew
                }).then((response) => {
                    if (response.status === 200) {
                        this.leDaten.benutzerEmail = this.emailNew;
                        this.waitingForCaptcha = false;
                        this.supportMsg = "E-Mail-Adresse wurde erfolgreich geändert";
                        this.showMsg = 3;
                    }
                }).catch(error => {
                    if (error.response) {
                        //console.log(error.response.data.message); 
                        this.errorMessage = error.response.data.message;
                        this.waitingForCaptcha = false;
                    }
                });
            },
            cancel() {
                return this.emailNew = this.leDaten.benutzerEmail;
            },
            async getLEInfo() {
                if (!this.leistungserbringer)
                    return;
                this.loading = true;
                //eventBus.$emit('resetLogoutTimer', {});
                //const le = this.$store.state.currentUser.leistungserbringer;
                await this.$http.get('/support', {
                    params: {
                        IK: this.leistungserbringer
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            let vm = this;
                            this.leDaten = response.data.find(le => le.ik === parseInt(le.loginname));
                            this.emailNew = this.leDaten.benutzerEmail;
                            this.panel = [0, 1, 2];

                            //response.data.map(e => {
                            //});
                            //console.log('getLEInfo Daten:', response.data, this.leDaten);
                            //console.log('response.data.find(le => le.ik === parseInt(le.loginname))',)
                        }
                    }
                    this.loading = false;
                }).catch(error => {
                    if (error.response) {
                        this.errorMessage = error.response.errorMessage;
                    }
                    this.loading = false
                });
            },
            async sendRegCode(ik, send) {
                if (!ik || send ==null)
                    return;
                try {

                    this.SupportMsg = "";
                    this.showMsg = 0;
                    //console.log('Anrede: ', parseInt(parseInt(this.benutzer.anrede)), 'Nachname: ', this.benutzer.nachname, 'Vorname: ', this.benutzer.vorname);
                    let response = await this.$http.post('support/reseteinladung', {
                        IK: ik,
                        Anrede: parseInt(this.benutzer.anrede),
                        Nachname: this.benutzer.nachname,
                        Vorname: this.benutzer.vorname,
                        EinladungPost: send
                    });
                    if (response.status === 200) {
                        await this.getLEInfo(); //TODO: Anpassung damit nur das Datum aktualisiert wird!

                        //console.log("Einladung erfolgreich verlängert oder gesendet!")
                        if (send) {
                            this.supportMsg = "Eine neue Einladung wird in den nächsten Tagen verschickt";
                            this.showMsg = 1;
                        }
                        if (send === false) {
                            this.supportMsg = "Der Registrierungscode wurde verlängert";
                            this.showMsg = 2;
                        }
                        this.benutzer = {
                            anrede: '',
                            nachname: '',
                            vorname: ''
                        }
                        this.$refs.supportForm.resetValidation();
              
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                      
                    }
                }
            },
            async sendVerifizierungsmail(ik) {
                if (!ik)
                    return;
                try {
                    this.SupportMsg = "";
                    this.showMsg = 0;

                    let response = await this.$http.post('support/resetverificationlink', {
                        IK: ik
                    });
                    if (response.status === 200) {
                        console.log("Verifizierungsemail wurde erfolgreich gesendet!")
                        //Module aufrufen
                        await this.getLEInfo();  //TODO: Anpassung damit nur das Datum aktualisiert wird!

                        this.supportMsg = "Verifizierungsemail wurde erfolgreich gesendet!";
                        this.showMsg = 3;
                        //this.initData();
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                       
                    }
                }
            },
            async sendZweiFA(loginname) {
                if (!loginname)
                    return;
                try {

                    this.supportMsg = "";
                    this.showMsg = 0;

                    let response = await this.$http.post('support/sendzweifa', {
                        LoginNameBenutzer: loginname
                    });
                    if (response.status === 200) {
                        console.log("ZweiFA-Link wurde erfolgreich gesendet!")
                        //Module aufrufen
                        await this.getLEInfo();  //TODO: Anpassung damit nur das Datum aktualisiert wird!
                        //this.initData();
                        this.supportMsg = "2FA-Link wurde erfolgreich gesendet!";
                        this.showMsg = 4;
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                     
                    }
                }
            }
        }
    }
</script>
